import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import StoreProvider from './components/Store/Provider';
//import './scss/style.scss'; // migrado para os componentes de Temas mais abaixo. 

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login/Login'));
const Logout = React.lazy(() => import('./views/Login/Logout'));

const TemaProducao = React.lazy(() => import('./utils/temaProducao'));
const TemaHomologacao = React.lazy(() => import('./utils/temaHomologacao'));

const AgendaOnLineIndex = React.lazy(() => import('./views/AgendaOnLine/Index'));

class App extends Component {

  render() {
    return (
      <>
        {/*  https://prawira.medium.com/react-conditional-import-conditional-css-import-110cc58e0da6  */}
        <React.Suspense fallback={<></>}>
          {process.env.REACT_APP_ENV.trim() === 'local' && <TemaHomologacao />}
          {process.env.REACT_APP_ENV.trim() === 'homologacao' && <TemaHomologacao />}
          {process.env.REACT_APP_ENV.trim() === 'producao' && <TemaProducao />}
        </React.Suspense>      
        <HashRouter>
            <React.Suspense fallback={loading}>
              <StoreProvider>
                <Switch>
                  <Route exact path="/login" render={props => <Login {...props}/>} />
                  <Route exact path="/logout" render={props => <Logout {...props}/>} />
                  <Route exact path="/agendaonline/:token" render={props => <AgendaOnLineIndex {...props}/>} />
                  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch>
              </StoreProvider>
            </React.Suspense>
        </HashRouter>
      </>
    );
  }
}

export default App;
